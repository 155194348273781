// 添加cf人机验证
import { ref, onMounted, Ref } from 'vue';
import { useTenantStore } from "@/store/tenant";

function useAddCFTurntile(hToken: Ref<string | undefined>, nodeId: string = 'cf-turnstile') {

  onMounted(async () => {
    if (window.turnstile) {
      const notRender = document.getElementById(nodeId)?.childElementCount === 0;
      const tenantStore = useTenantStore() // 商户store
      await tenantStore.getAuthInfo();
      const captchaSwitch = tenantStore.authInfo?.captchaSwitch;
      if(captchaSwitch == 'ON' && notRender) {
        window.turnstile.render(`#${nodeId}`, {
          sitekey: import.meta.env.VITE_TURNSTILE_PUBLIC_KEY,
          size: 'flexible',
          callback: (token: string) => {
            hToken.value = token;
          }
        });
      }
    } else {
      console.error('Turnstile is not loaded');
    }
  });
}

export default useAddCFTurntile;
