import { useTenantStore } from "@/store/tenant";
import { defineAsyncComponent, markRaw, computed, ComponentPublicInstance } from 'vue';

export interface ComponentList { 
  'blue-default-GameType'?: () => Promise<ReturnType<typeof defineAsyncComponent>>; 
  'blue-default-v01-GameType'?: () => Promise<ReturnType<typeof defineAsyncComponent>>;
  'blue-default-Platform'?: () => Promise<ReturnType<typeof defineAsyncComponent>>;
  'green-default-v01-GameType'?: () => Promise<ReturnType<typeof defineAsyncComponent>>;
  'green-default-v01-Platform'?: () => Promise<ReturnType<typeof defineAsyncComponent>>;
  'green-default-v02-Platform'?: () => Promise<ReturnType<typeof defineAsyncComponent>>;
  'forest-green-v01-Platform'?: () => Promise<ReturnType<typeof defineAsyncComponent>>;
  'forest-green-v02-Platform'?: () => Promise<ReturnType<typeof defineAsyncComponent>>;
}

export default () => {
  const tenantStore = useTenantStore();

  const {
    themeConfig
  } = toRefs(tenantStore);

  const themeHomeType = computed(() => {
    const { theme, home, homeType } = tenantStore.themeConfig ?? {};
    return  `${theme}-${home ? (home + '-') : ''}${homeType ?? 'GameType'}`;
  });

  const svgThemeKey: Record<string, string[]> = {
    '1': ['green-default-v01-Platform', 'green-default-v02-Platform', 'forest-green-v01-Platform', 'forest-green-v02-Platform'],
    '2': ['blue-default-GameType', 'blue-default-v01-GameType'],
  }

  const selectSvgGradientID = () => {
    const list = Object.keys(svgThemeKey);
    for (const key of list) {
      if (svgThemeKey[key].includes(themeHomeType.value)) {
        return key;
      }
    }
  }

  const headerLanguageHideList = ['green-default-v02-Platform', 'forest-green-v02-Platform'];

  const pwaHeaderCloseBtnInStart = ['green-default-v01-Platform', 'green-default-v02-Platform', 'forest-green-v01-Platform', 'forest-green-v02-Platform']

  const themeLogos: Record<string, any> = {
    'blue-default-GameType': {
      logo1: '/icons/sort/POPULAR_on.png',
      logo2: '/icons/sort/SPORTS_on.png',
    },
    'blue-default-Platform': {
      logo1: '/first/svg/sort/POPULAR1.svg',
      logo2: '/first/svg/sort/SPORTS1.svg',
    },
    'blue-default-v01-GameType': {
      logo1: '/first/svg/sort/POPULAR3.svg',
      logo2: '/first/svg/sort/SPORTS3.svg',
    },
    'green-default-v01-Platform': {
      logo1: '/first/svg/sort/POPULAR2.svg',
      logo2: '/first/svg/sort/SPORTS2.svg',
    },
    'green-default-v02-Platform': {
      logo1: '/first/svg/sort/POPULAR2.svg',
      logo2: '/first/svg/sort/SPORTS2.svg',
    },
    'forest-green-v01-Platform': {
      logo1: '/first/svg/sort/POPULAR2.svg',
      logo2: '/first/svg/sort/SPORTS2.svg',
    },
    'forest-green-v02-Platform': {
      logo1: '/first/svg/sort/POPULAR2.svg',
      logo2: '/first/svg/sort/SPORTS2.svg',
    },
  };

  const gameHeadTypes: Record<string, any> = {
    'blue-default-GameType': () => ({
      titleType: '2',
      headType: '1',
      size: 6,
      row: 2,
      hotSize: 9,
      hotRow: 3,
    }),
    'blue-default-Platform': () => ({
      titleType: '1',
      headType: '3',
      isShowAll: true,
      size: 12,
      row: 3,
      hotSize: 16,
      hotRow: 4,
    }),
    'blue-default-v01-GameType': () => ({
      titleType: '1',
      headType: '6',
      size: 16,
      row: 4,
      hotSize: 16,
      hotRow: 4,
    }),
    'green-default-v01-GameType': () => ({
      titleType: '1',
      headType: '1',
      size: 12,
      row: 3,
      hotSize: 16,
      hotRow: 4,
    }),
    'green-default-v01-Platform': () => ({
      titleType: '1',
      headType: '4',
      isShowAll: true,
      size: 12,
      row: 3,
      hotSize: 16,
      hotRow: 4,
    }),
    'green-default-v02-Platform': () => ({
      titleType: '1',
      headType: '5',
      isShowAll: true,
      size: 9,
      row: 3,
      hotSize: 12,
      hotRow: 4,
    }),
    'forest-green-v01-Platform': () => ({
      titleType: '1',
      headType: '4',
      isShowAll: true,
      size: 12,
      row: 3,
      hotSize: 16,
      hotRow: 4,
    }),
    'forest-green-v02-Platform': () => ({
      titleType: '1',
      headType: '5',
      isShowAll: true,
      size: 9,
      row: 3,
      hotSize: 12,
      hotRow: 4,
    }),
  }


  // 组件加载器
  const loadComponent = (list: ComponentList) => {
    const components = list[themeHomeType.value as keyof ComponentList] || list['blue-default-GameType'] || null;
    if (components) {
      const asyncComponent = defineAsyncComponent(components);
      return markRaw(asyncComponent) as unknown as ComponentPublicInstance | null;
    }
    return null;
  };

  return {
    themeLogos,
    gameHeadTypes,
    themeConfig,
    loadComponent,
    themeHomeType,
    selectSvgGradientID,
    headerLanguageHideList,
    pwaHeaderCloseBtnInStart,
  };
}