import { decryptData } from "@/utils";

export function getMinxinsInfo(urlSearch?: string) {

  const urlParams = new URLSearchParams(urlSearch || window.location.search);
  const entries = Object.fromEntries(urlParams.entries());
  if (!urlParams.has('info')) // 兼容旧版
    return entries;

  const minxinsInfo = decryptData(urlParams.get('info'));
  const params = {
    ...entries,
    ...Object.fromEntries(new URLSearchParams(minxinsInfo || '').entries()),
  };
  delete params.unTopWindow; //排除c域名
  delete params.info; // 排除info
  return params
}
