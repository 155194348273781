import { fetchRemoteConfig } from "@/utils";
import useReDomainModal from '@/pwa/hooks/useReDomainModal';
import { App } from "vue";

export const bootstrap = async (app: App) => {
  const remoteConfig = await fetchRemoteConfig(); // 同步配置
  if (remoteConfig.value) {
    window.__APP_CONFIG__ = remoteConfig.value;
  }
  useReDomainModal();
  window.isOpenMainUI = true
  await app.mount('#app'); // 挂载app
  // @ts-ignore
  const { initVersionPolling } = await import('@/utils/version');
  await initVersionPolling(); // 版本检测
}
