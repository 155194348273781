import router from "@/router";
import { openUrl, openWindow, getMinxinsInfo, encryptData } from "@/utils";
import { launchCheck } from "@/utils/pwa/launch";
import { installCheck } from "@/utils/pwa/install";
import { downLoadCheck } from "@/utils/pwa/downLoad";
import { showLoading } from "@/utils/loading";
import { httpCompletion } from "@/hooks/HttpCompletion";
import { getTheme } from '@/theme/hooks'
import { BROWSER } from '@/enums/device';
import { random } from 'lodash-es'
import { checkBrowser } from '@/utils/pwa/browser'
import { emitter } from "@/utils/event";
import openAPK from '@/utils/pwa/openAPK';
import { modalController } from '@ionic/vue';
import { checkDomainAvailability } from '@/hooks/ping';
import {closeForceModal} from '@/utils/pwa/forcedModal/useClose'
import { getJumpGoogleUrl } from '@/utils/pwa/getJumpGoogleUrl';
import useLoadComponent from '@/hooks/useLoadComponent';

export const visibleGuideClose = ref(true)
export default () => {
  const appStore = useAppStore(); // 用户store
  const userStore = useUserStore(); // 用户store
  const tenantStore = useTenantStore(); // 租户store
  const systemStore = useSystemStore(); // 系统store
  const channelStore = useChannelStore(); // 渠道store
  const statusStore = useStatusStore(); // 状态信息
  const { user, isAppUser } = toRefs(userStore) // 用户信息

  const { t } = useI18n() // 国际化
  const os = computed(() => systemStore.app?.build || ""); // 系统类型
  const tenantInfo = computed(() => tenantStore.tenantInfo); // 租户信息
  const installProgress = ref(0); // 安装进度
  let initProgress = 0.01
  let initTime = 240
  let updateStats = 'Installed'
  const { browser, isPwaVisible, isApk, isApp, isInAppBrowser, isPwa, isIOSApp } = toRefs(systemStore)

  const { pwaLaunchAllow, unStandalone, isShowGuidePwa, pwaFooterVisible, modalVisible, pwaBarVisible } = toRefs(appStore)
  const { installStatus, promotionInfo, isShelfPackage: isPackage } = toRefs(channelStore)
  const { theme, skin } = getTheme()


  const pwaAllow = computed(() => isPwaVisible.value && unStandalone.value); // 是否允许PWA安装
  const isShelfPackage = computed(() => isPackage.value && isApk.value); // 是否是上架包
  const isInstallAPK = computed(() => promotionInfo.value?.installType === "APK"); // 是否强制安装apk
  const isSamsung = computed(() => browser.value === BROWSER.SAMSUNG_INTERNET); // 是否是三星浏览器
  const isFacebook = computed(() => browser.value === 'Facebook');
  const isShowPwaInfo = computed(() => (!isPwa.value && !isApp.value && promotionInfo.value?.downloadBtn && !isAppUser.value && !isIOSApp.value)); // 是否显示PWA信息
  // 是否显示PWA顶部安装栏
  const isShowPwaBar = computed(
    () => {
      if (!isShowPwaInfo.value) {
        appStore.isShowPwaBar = false
        return false
      }


      return appStore.isShowPwaBar = pwaBarVisible.value

    }
  );
  // 是否显示PWA底部栏
  const isShowPwaFooter = computed(() => {
    if (!isShowPwaInfo.value) {
      appStore.isShowPwaFooter = false
      return false
    }
    return appStore.isShowPwaFooter = pwaFooterVisible.value && !pwaLaunchAllow.value && !modalVisible.value
  }

  );

  // 只有配置pwa要拉起pwa
  const btnText = computed(() => {
    if (promotionInfo.value?.installType === "PWA") {
      return t('label.launchApp')
    }
    return t('viewsSystem.downloadIndex04')
  })

  const {
    themeHomeType,
  } = useLoadComponent();

  const giftImgUrl = computed(() => {
    const imgUrls = {
      '/images/money2.png': ['blue-default-v01-GameType'],
    }
    for (const [imgUrl, theme] of Object.entries(imgUrls)) {
      if (theme.includes(themeHomeType.value)) {
        return imgUrl
      } else {
        return '/images/pwa-prize.png'
      }
    }
  })
  const finishedTodo = () => {
    if (!isSamsung.value) return
    if (installProgress.value < 0.82) {
      installProgress.value = 0.82
    }
    initProgress = 0.1
    initTime = 20
  }
  const updateProgress = () => {
    updateStats = 'NotInstall'
    initProgress = 0.34
    initTime = 1000
  }
  onMounted(() => {
    window.addEventListener('appinstalled', finishedTodo);
    emitter.on('pwa/updateProgress', updateProgress)

  })
  onUnmounted(() => {
    window.removeEventListener('appinstalled', finishedTodo);
  })

  const updateToInstalled = () => {
    channelStore.setInstallStatus('Installed');
    appStore.setPwaFooterVisible(false);
    appStore.setPwaLaunchAllow(true);
    if (isSamsung.value) {
      appStore.isShowGuidePwa = true;
    }

  }
  const updateToNotInstall = () => {
    channelStore.setInstallStatus('NotInstall');
    installProgress.value = 0
  }
  const updateMap = {
    Installed: updateToInstalled,
    NotInstall: updateToNotInstall
  }
  // 安装进度
  watch(
    () => installStatus.value,

    (val) => {
      if (val === 'Installing') {
        const timer = setInterval(() => {
          if (installProgress.value < 1) {
            if (installProgress.value + initProgress > 1) {
              installProgress.value = 1
            } else {
              installProgress.value += initProgress;
            }

          } else {
            installProgress.value = 1;
            if (updateStats in updateMap) {
              updateMap[updateStats as keyof typeof updateMap]();
            }
            clearInterval(timer);
          }
        }, initTime);
      }
    },
  );


  /**
  * @description 关闭模态框
  */

  /**
  * @description 关闭模态框
  */
  const closeFooterModal = () => {
    appStore.setPwaFooterVisible(false);
  }

  /**
  * @description 关闭PWA栏
  */
  async function closeHeaderBar() {
    appStore.isShowPwaBar = false
  }
  /**
   * @description 打开外部浏览器
   */
  const openChrome = async () => {
    const baseUrl = location.origin;
    const acc = await appStore.getAccount();
    const pass = await appStore.getPassword();
    const { sd, ...restQuery } = router.currentRoute.value.query;
    const query = buildUrlParam({ ...restQuery, token: appStore.token, acc, pass });
    openWindow(`${baseUrl}${query}`);
  }
  /**
   * @description 联系客服
   */
  const onlineServiceHandle = async () => {
    showLoading();
    const res = await tenantStore.getCustomerService();
    if (res.onlineServices.length) {
      const index = random(0, res.onlineServices.length - 1);
      const link = res.onlineServices[index].link;
      openUrl(httpCompletion(link));
    }
  }
  // 首页顶部bar拉起pwa
  const pullUpPwaHomeBar = () => {
    if (isSamsung.value) {
      statusStore.setHomeInstallModalVisible(true);
      appStore.isShowGuidePwa = true;
      appStore.isShowInstallModalContent = false;
      visibleGuideClose.value = false
      return
    }
    launchCheck()
  }
  const isGotoGoogleBrowser = () => {
    if (isInAppBrowser.value) return true
    return !isPwaVisible.value && promotionInfo.value?.installType === "PWA"
  }
  // installType是pwa要拉起pwa
  const downLoadTodo = (cb: () => void = pullUpPwaHomeBar) => {
    if (isGotoGoogleBrowser()) {
      appStore.isShowFacebookModal = isFacebook.value
      return checkBrowser()
    }
    if (promotionInfo.value?.installType === "PWA") {
      return cb()
    }
    downLoadCheck()
  }
  const wakeUpApk = () => {
    if (isInAppBrowser.value) {
      appStore.isShowFacebookModal = isFacebook.value
      return checkBrowser()
    }
    copyTextAppendEL.value = 'compulsory-warp'
    openAPK(true)
  }
  const handleBubbleEvent = () => {
    copyTextAppendEL.value = 'compulsory-warp'
  }
  const computedAmonut = (isArrayAmount: boolean, showGiftAmount: number | number[]) => {
    if (isArrayAmount && Array.isArray(showGiftAmount)) {
      return ` ${tenantInfo.value?.merchantCy}${convertMoneyToShow(showGiftAmount[0])}-${convertMoneyToShow(showGiftAmount[1])}`;
    }
    return ` ${tenantInfo.value?.merchantCy}${convertMoneyToShow(showGiftAmount as number)}`;
  }
  return {
    giftImgUrl,
    pwaLaunchAllow,
    pwaAllow,
    user,
    os,
    promotionInfo,
    isShelfPackage,
    tenantInfo,
    installStatus,
    installProgress,
    theme,
    unStandalone,
    isSamsung,
    isShowGuidePwa,
    appStore,
    isShowPwaBar,
    isShowPwaFooter,
    btnText,
    isInstallAPK,
    isPwa,
    wakeUpApk,
    closeForceModal,
    openChrome,
    launchCheck,
    installCheck,
    downLoadCheck,
    onlineServiceHandle,
    closeFooterModal,
    closeHeaderBar,
    pullUpPwaHomeBar,
    downLoadTodo,
    handleBubbleEvent,
    skin,
    computedAmonut //处理金额格式
  }
}

export const useRedomain = () => {

  const { domainInfo } = window.__APP_CONFIG__ || {};
  const { rescueDomainList } = domainInfo?.result?.data?.json?.info;

  const urlParams = encryptData(window.location.search);
  const installRePwa = async () => {
    const url = `?info=` + urlParams + '&from=' + 'testaaa';
    if (rescueDomainList?.length) {
      for (const item of rescueDomainList) {
        const domain = await checkDomainAvailability(item?.jumpDomain)
        if (domain) {
          openUrl(`${domain}` + url, 'Kwai');
          break
        }
      }
    }
  }
  return {
    installRePwa,
  }
}

