import { modalController } from '@ionic/vue';
import { getUrlParam } from '@/utils';
import { getTheme } from '@/theme/hooks'

export interface CompulsoryModalProps {
  closeModalX?: boolean;
  templateText?: {
    title?: string;
    description?: string;
    commission: number | number[];
  };
  buttonType?: 're-domain' | 'install' | string;
}
type DomainAmountConfig = {
  "bonusStatus": "disable" | "enable" | string;
  "giftAmountType": "fixed" | string;
  "giftMinAmount": number;
  "giftMaxAmount"?: number,
  "content": string
}
/**
 * @description 当域名不可用时显示强制安装弹窗
 */
export default async () => {
  // 检查是否从 Service Worker 打开
  const isFromSw = () => getUrlParam('fromEntry') === 'sw';
  // 获取域名配置信息
  const { domainInfo } = window.__APP_CONFIG__ || {};
  const { rescueDomainList, rescueDomainAmountConfig } = domainInfo?.result?.data?.json?.info;
  const conf = typeof rescueDomainAmountConfig === 'string' ? JSON.stringify(rescueDomainAmountConfig) : rescueDomainAmountConfig;
  // 非 SW 且无可用域名时不显示弹窗
  if (!(isFromSw() && rescueDomainList?.length > 0)) // 如果从sw打开且有可用域名，则显示弹窗
    return;
  // 根据主题加载对应组件
  const { skin } = getTheme();
  const modalComponents = {
    'default': () => import('@/pwa/compulsoryModal/default.vue'),
    'second': () => import('@/pwa/compulsoryModal/second.vue')
  };

  const component = await modalComponents[skin as keyof typeof modalComponents]();

  const computedCommission = (conf: DomainAmountConfig) => {
    const isfixed = conf?.giftAmountType?.includes('fixed');
    return isfixed ? [conf?.giftMinAmount, conf?.giftMaxAmount] : conf?.giftMinAmount
  }

  // 创建并显示弹窗
  const modal = await modalController.create({
    component: component.default,
    id: 'pwa-re-domain-modal',
    componentProps: <CompulsoryModalProps>{
      buttonType: 're-domain',
      closeModalX: true,
      templateText: {
        title: conf?.bonusStatus !== 'disable' ? 'ForceBindings.000002' : 'ForceBindings.000003',
        description: conf?.content || '',
        commission: computedCommission(conf) || 0
      }
    }
  });

  modal.present();
}
