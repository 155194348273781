
import { isGray } from "@/utils/app";

export function getApiUrl() {
  if (import.meta.env.VITE_USE_API_PROXY) {
    return ''
  }
  return import.meta.env.VITE_TRPC_URL || 'http://localhost:3000'
}


export function getEnvHeaders() {
  const headers: Record<string, string> = {};

  if (import.meta.env.VITE_ENV === 'dev') {
    headers['X-Dev'] = 'true';
  }

  if (isGray()) {
    headers['X-Gray'] = 'true';
  }

  return headers;
}
