import { postParent, getUrlParam, getUrlParams } from '@/utils';
import { appendTrackerParams } from '@/utils/ad/useGetTracker';
import { isEmpty } from 'lodash-es';
const apiUrl = 'api/public/health';
const TIME_INTERVAL = 5000;
type checkDomainStatus = 'checking' | 'available' | 'unavailable';
type TDomainInfo = {
  jumpDomain: string;
  jumpDomainId: number;
}
export const checkDomainAvailability = async (domain: string) => {
  try {
    const res = await (await fetch(`https://${domain}/${apiUrl}`)).json();
    return res?.ok ? domain : false;
  } catch (error) {
    return false;
  }
}
export const useDomain = () => {
  let interval: NodeJS.Timeout;
  const isPwa = () => useSystemStore().isPwa;
  const isUnTopWindow = () => getUrlParam('unTopWindow') === 'true';
  const domainType = () => getUrlParam('domainType') === "google";
  const isLoaderIframe = () => ((!isUnTopWindow()) && isPwa() && domainType());
  const tenantStore = useTenantStore();
  const domainInfo = computed(() => tenantStore?.domainInfo);
  const getRandomDomain = (list: TDomainInfo[]) => list[Math.floor(Math.random() * list.length)]?.jumpDomain;
  const currentDomain = ref("");
  const onCheckDomain = ref<checkDomainStatus>('checking');
  const checkedDomains = new Set(); // 检查过且不通的域名
  const getReceviceMessage = async (): Promise<string> => await useAppStore().getReceviceMessage();
  const { clear: clearPostParent } = postParent(); // 初始化postParent
  const buildIframeDomain = async () => {
    const getmessage = await getReceviceMessage();
    const fromUrlParams = getUrlParams();
    delete fromUrlParams?.domainType;
    const toUrlParams = Object.fromEntries(new URLSearchParams(getmessage));
    const outputUrlParams = buildUrlParam({ ...fromUrlParams, ...toUrlParams });
    const receviceMessage = isEmpty(outputUrlParams) ? '' : outputUrlParams;
    return receviceMessage;
  };
  const checkDomainAndUpdate = async (list: TDomainInfo[]) => {
    console.log('currentDomain', currentDomain.value);
    const currentDomainValue = currentDomain.value.replace(/\?.*$/, '');
    const availableDomain = await checkDomainAvailability(currentDomainValue);
    const receviceMessage = await buildIframeDomain();
    checkedDomains.add(currentDomainValue);
    console.log('availableDomain', availableDomain, 'checkedDomains', checkedDomains);
    if (availableDomain) {
      const buildDomain = availableDomain + receviceMessage;
      currentDomain.value = buildDomain;
      onCheckDomain.value = 'available';
    } else {
      // 随机一个域名
      const isAvailableDomain = list.filter(item => !checkedDomains.has(item.jumpDomain));
      console.log('isAvailableDomain', isAvailableDomain);
      const randomDomain = getRandomDomain(isAvailableDomain); // 过滤掉已检查过的域名
      if (!checkedDomains.has(randomDomain) && await checkDomainAvailability(randomDomain)) { // 如果未检查过且可用，则设置为当前域名
        currentDomain.value = randomDomain + receviceMessage;
        onCheckDomain.value = 'available';
      } else {
        checkedDomains.add(randomDomain);
        onCheckDomain.value = 'checking';
      }
    }
  }
  watch(domainInfo, (newVal) => { // 监听域名列表变化 
    if (newVal?.landingDomainList && isPwa()) {
      console.log('landingDomainList: domain list for blocking prevention', newVal.landingDomainList);
      try {
        const list = newVal.landingDomainList;
        if (list?.length) {
          currentDomain.value = list[0].jumpDomain;
          checkDomainAndUpdate(list);
        } else {
          onCheckDomain.value = 'checking';
        }
      } catch (error) {
        console.error('Failed to parse domain list:', error);
        onCheckDomain.value = 'checking';
      }
    }
  })
  const clearIntervalFn = () => interval && window.clearInterval(interval);
  interval = window.setInterval(async () => {
    if (isUnTopWindow()) { // 如果是不顶层窗口，则不检查域名
      return;
    }
    if (domainType() && isPwa()) { // 如果是防封域名，则检查域名  
      await checkDomainAndUpdate(domainInfo.value?.landingDomainList || []);
    }
  }, TIME_INTERVAL) as unknown as NodeJS.Timeout;
  onBeforeUnmount(() => {
    clearIntervalFn();
    clearPostParent();
  })
  const iframeSrc = computed(() => appendTrackerParams(`https://${currentDomain.value}`));
  return {
    isUnTopWindow,
    currentDomain,
    onCheckDomain,
    isLoaderIframe,
    getReceviceMessage,
    iframeSrc,
  }
}

export const useAppLogo = () => {
  const tenantStore = useTenantStore();
  const appLogo = ref("");
  watchEffect(() => {
    if (tenantStore.isLoadedDomainInfo) {
      appLogo.value = tenantStore.domainInfo?.jumpDomainType === 'google' ? '' : tenantStore.tenantInfo?.logo;
    }
  });
  return {
    appLogo
  }
}

